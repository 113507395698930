import { render, staticRenderFns } from "./health-record-main-popup.html?vue&type=template&id=3feff491&scoped=true&"
import script from "./health-record-main-popup.js?vue&type=script&lang=js&"
export * from "./health-record-main-popup.js?vue&type=script&lang=js&"
import style0 from "./health-record-main-popup.scss?vue&type=style&index=0&id=3feff491&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3feff491",
  null
  
)

export default component.exports