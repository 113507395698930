import { render, staticRenderFns } from "./upload-multiple-file-of-health-record.html?vue&type=template&id=15a8dc16&scoped=true&"
import script from "./upload-multiple-file-of-health-record.js?vue&type=script&lang=js&"
export * from "./upload-multiple-file-of-health-record.js?vue&type=script&lang=js&"
import style0 from "./upload-multiple-file-of-health-record.scss?vue&type=style&index=0&id=15a8dc16&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a8dc16",
  null
  
)

export default component.exports